import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import CustomLink from "./customLink"

const HelloBar = () => {
  const data = useStaticQuery(graphql`
    query HellobarQuery {
      hellobar: prismicHellobar {
        data {
          activated
          text {
            text
          }
          link {
            url
            target
            uid
            type
            link_type
          }
          link_text {
            text
          }
        }
      }
    }
  `)
  if (!data.hellobar.data || !data.hellobar.data.activated) return null
  return (
    <div className="hello-bar">
      <div>
        <span>{data.hellobar.data.text.text}</span> &mdash; <CustomLink link={data.hellobar.data.link}>{data.hellobar.data.link_text.text}</CustomLink>
      </div>
    </div>
  )
}

export default HelloBar
