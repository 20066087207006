import React from "react"

const PreviewBar = () => {
  if (typeof window === 'undefined' || !window.__PRISMIC_PREVIEW_DATA__) {
    return null
  }

  const reload = () => {
    window.location.reload(true)
  }

  return (
    <div className="preview-bar">Preview Mode <button onClick={reload}>Click to Exit</button></div>
  )
}

export default PreviewBar
