import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import HelloBar from "./helloBar"
import PreviewBar from "./previewBar"

const Layout = ({ mainClass, children }) => {
  const data = useStaticQuery(graphql`
    query SiteGlobalQuery {
      site: site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <PreviewBar />
      <HelloBar />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={mainClass}>{children}</main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </>
  )
}

Layout.propTypes = {
  mainClass: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  mainClass: '',
}

export default Layout
