import { useStaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import HubspotForm from 'react-hubspot-form'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const RequestDemoForm = ({ closeCallback }) => {
  const data = useStaticQuery(graphql`
    query RequestDemoForm {
      prismicRequestDemoForm {
        data {
          form_headline {
            text
          }
          success_message {
            html
            text
          }
        }
      }
    }
  `).prismicRequestDemoForm.data

  const [form, setState] = useState({
    name: '',
    email: '',
    company_name: '',
    employees: '',
    desks: '',
    rooms: '',
    message: '',
  })

  // 0: not submitted, 1: submit success, 2: submit error
  const [submitStatus, setSubmitStatus] = useState({status: 0, message: ''})

  const handleSubmit = e => {
    e.preventDefault()
    console.log(form)
    const formEle = e.target

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formEle.getAttribute('name'),
        ...form
      })
    })
    .then(response => {
      console.log(response);

      if (response.status === 200) {
        setSubmitStatus({
          status: 1,
          message: 'Success!'
        })
      } else {
        setSubmitStatus({
          status: 2,
          message: response.statusText
        })

        console.log(response)
      }
    })
    .catch(error => {
      setSubmitStatus({
        status: 2,
        message: error
      })

      console.log(error)
    });
  }

  const updateField = e => {
    setState({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const tryAgain = () => {
    setSubmitStatus({
      status: 0,
      message: ''
    })
  }

  return (
    <div className="request-demo">
      <button onClick={closeCallback} className="close">Close</button>

      {submitStatus.status === 2 &&
        <div className="status">
          <h5>There was an error with your submission. <span className="small">{submitStatus.message}</span></h5>
          <p><button onClick={tryAgain} className="button highlight-green">Try again</button></p>
        </div>
      }

      {submitStatus.status === 1 &&
        <div className="status" dangerouslySetInnerHTML={{__html: data.success_message.html}}></div>
      }

      {submitStatus.status === 0 &&
        <div className="form">
          <h3>{data.form_headline.text}</h3>
          <HubspotForm
            portalId={process.env.GATSBY_REQUEST_DEMO_HUBSPOT_FORM_PORTAL_ID}
            formId={process.env.GATSBY_REQUEST_DEMO_HUBSPOT_FORM_ID}
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form Ready')}
            loading={<div>Loading..</div>}
            css=''
          />
        </div>
      }
    </div>
  )
}

export default RequestDemoForm
