import { Link } from "gatsby"
import React, { useEffect } from "react"
import Modal from "react-modal"

import RequestDemoForm from "./requestDemoForm"

const Header = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    document.body.classList.remove("nav-open")
    Modal.setAppElement("#___gatsby")
  })

  const openNav = e => {
    document.body.classList.toggle("nav-open")
  }

  const openRequestDemoModal = () => {
    setIsOpen(true)
  }

  const closeRequestDemoModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <header>
        <div className="inner">
          <div className="logo">
            <Link to="/">VergeSense</Link>
          </div>

          <nav>
            <Link to="/product" activeClassName="active" partiallyActive={true}>
              Product
            </Link>
            <Link
              to="/solutions"
              activeClassName="active"
              partiallyActive={true}
            >
              Use Cases
            </Link>
            <Link
              to="/customers"
              activeClassName="active"
              partiallyActive={true}
            >
              Customers
            </Link>
            <Link
              to="/partners"
              activeClassName="active"
              partiallyActive={true}
            >
              Partners
            </Link>
            <Link
              to="/innovations"
              activeClassName="active"
              partiallyActive={true}
            >
              Innovations
            </Link>
            <Link to="/company" activeClassName="active" partiallyActive={true}>
              Company
            </Link>
            <button
              id="request-demo-link"
              onClick={openRequestDemoModal}
              onKeyPress={openRequestDemoModal}
              className="highlight-green"
            >
              Request Demo
            </button>
          </nav>

          <button className="hamburger" onClick={openNav} onKeyPress={openNav}>
            Open Menu
          </button>
        </div>
      </header>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeRequestDemoModal}
        contentLabel="Request Demo Form"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <RequestDemoForm closeCallback={closeRequestDemoModal} />
      </Modal>
    </>
  )
}

export default Header
