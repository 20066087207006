import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { triggerRequestDemoClick } from "./_helpers/triggerRequestDemoClick"

const Footer = ({ siteTitle }) => (
  <footer>
    <div className="inner">
      <div className="links">
        <div className="column logo">
          <Link to="/">
            <img src="/logo-square-green.svg" height="80" alt="VergeSense" />
          </Link>
        </div>

        <div className="column">
          <button onClick={triggerRequestDemoClick} className="highlight-green">
            Request Demo
          </button>
          <Link to="/product" activeClassName="active" partiallyActive={true}>
            Product
          </Link>
          <Link to="/solutions" activeClassName="active" partiallyActive={true}>
            Use Cases
          </Link>
          <Link to="/customers" activeClassName="active" partiallyActive={true}>
            Customers
          </Link>
          <Link to="/partners" activeClassName="active" partiallyActive={true}>
            Partners
          </Link>
        </div>

        <div className="column">
          <Link
            to="/innovations"
            activeClassName="active"
            partiallyActive={true}
          >
            Innovations
          </Link>
          <Link to="/company" activeClassName="active" partiallyActive={true}>
            Company
          </Link>
          <a
            href="https://jobs.lever.co/vergesense"
            target="_blank"
            rel="noopener noreferrer"
          >
            Careers
          </a>
          <a
            href="/legal/VergeSense,%20Inc.%20-%20Privacy%20Policy.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          <Link
            to="/trust-center"
            activeClassName="active"
            partiallyActive={true}
          >
            Trust Center
          </Link>
        </div>

        <div className="other-links">
          <a href="https://cloud.vergesense.com" className="outline">
            Log In
          </a>

          <div className="social">
            <a
              href="https://www.facebook.com/pages/category/Science--Technology---Engineering/VergeSense-738428159828368/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/icon-facebook.svg" alt="Facebook" height="25" />
            </a>
            <a
              href="https://twitter.com/VergeSense"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/icon-twitter.svg" alt="Twitter" height="20" />
            </a>
            <a
              href="https://www.linkedin.com/company/vergesense/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/icon-linkedin.svg" alt="LinkedIn" height="25" />
            </a>
          </div>
        </div>
      </div>

      <div className="meta">
        <a href="mailto:hello@vergesense.com">hello@vergesense.com</a>
        <a href="tel:+16176185006">+1 (617) 618 5006</a>
        <span>&copy; 2020. All Rights Reserved.</span>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
