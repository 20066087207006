import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CustomLink from "../../components/customLink"

const Data_BeforeAfterCovidOfficeStatistics = () => {
  const data = {
    hero_title: {
      text:
        "Average office utilization down 2.8X, but collaboration is on the rise",
    },
    hero_text: {
      text:
        "Learn how the pandemic impacted office use. VergeSense’s newest workplace study discusses findings and workplace predictions from over 40M square feet.",
    },
    hero_image: {
      url:
        "https://images.prismic.io/vergesense/03a744d2-e03f-43b2-9228-d6510e885ad1_header-data-report.png?auto=format%2Ccompress",
    },
  }

  return (
    <Layout>
      <SEO title="Data / Before and After COVID Office Statistics" />

      {/* <SimpleHero data={data} /> */}
      <section className="page-hero hero two">
        <div className="row">
          <div className="inner">
            <div className="col text center" data-sal="slide-up">
              <h3>{data.hero_title.text}</h3>
              <div class="subtitle">
                <p>{data.hero_text.text}</p>
              </div>
            </div>

            <div
              className="col asset"
              data-sal="slide-left"
              data-sal-delay="300"
            >
              <img src={data.hero_image.url} alt="" />
            </div>
          </div>
        </div>
      </section>

      <div className="page-section">
        <div className="inner">
          <h3 data-sal="slide-up" data-sal-delay="300">
            The pandemic brought life to a halt, giving us a unique opportunity
            to re-imagine the role of the physical workplace.
          </h3>

          <p>
            We know expectations around how and when employees will use the
            office have shifted, but have there been any signals for what that
            shift will be?
          </p>
          <p>
            Today, VergeSense is measuring over 40M square feet of real estate
            globally. We used this dataset to look for any signals that could
            help predict what employee office behavior might look like post
            pandemic.
          </p>

          <div class="page-inline-block-statistics">
            <p class="green">
              To achieve this, we compared samples from two similar time
              periods:
            </p>

            <div class="statistics-blocks">
              <div class="statistic-block">
                <h4>January 2020</h4>
                <h5 class="violet">PRE-PANDEMIC SAMPLE</h5>
                <p>
                  We selected January 2020 because it was our most comprehensive
                  data sample prior to the shutdowns in Europe and the Americas.
                </p>
              </div>
              <div class="statistic-block">
                <h4>January 2021</h4>
                <h5 class="light-red">MID-PANDEMIC SAMPLE</h5>
                <p>
                  A similar data period to account for holidays and seasonality
                  impacting work habits, ensuring comparable datasets.
                </p>
              </div>
            </div>

            <p>
              Next we categorized space types for either{" "}
              <strong>individual work</strong> (e.g. desk, office) or
              <strong>collaborative work</strong> (e.g. conference room, lounge,
              huddle).
            </p>
            <p class="green">
              Our dataset comparison revealed four key findings.
            </p>
          </div>
        </div>
      </div>

      <div class="page-section bone-white-bg">
        <div class="inner">
          <h5 class="violet">Finding #1</h5>
          <h3>On average, offices were only 30% utilized pre-pandemic</h3>

          <img
            src="https://images.prismic.io/vergesense/abe6fadd-6ba8-4d9b-9d10-e88dbc9a7190_average-office-utilization.png?auto=compress,format"
            alt=""
            class="float-right"
          />
          <p>
            In other words, we perceived “office life coming to a screeching
            halt,” however, it only decreased by 2.8X. Our data shows us that
            COVID-19 didn’t trigger a decline in office use, it accelerated it.
            This indicates that pre-pandemic, there was already ample
            opportunity to rethink and repurpose our real estate footprint to
            match the evolving needs of employees.
          </p>
          <p class="strong">What does this mean for business?</p>
          <p>
            This is an opportunity to redesign the workplace to be more
            impactful than it has been in years. Businesses that invest in
            workplace transformation will create a competitive advantage that
            will attract employees, get the best use of real estate, and better
            deliver on what employees need.
          </p>
          <p>
            There is also a strong opportunity to reassess overall portfolio
            strategy and diversify physical assets among percent of space owned,
            leased, coworking or other flexible space. This will help businesses
            better manage their real estate investment while also investing in
            space optimization.
          </p>
          <p class="strong">
            What kinds of spaces did employees value pre-pandemic? And did
            employee space allocation match those needs?
          </p>
        </div>
      </div>

      <div class="page-section dark-blue-bg">
        <div class="inner">
          <h5 class="highlight-green">Finding #2</h5>
          <h3>
            Pre-pandemic, 83% of office space was allocated to individual work.
            Only 17% was dedicated to collaborative work.
          </h3>

          <img
            src="https://images.prismic.io/vergesense/93552eac-1539-4c37-a4ba-625c84f8bc4d_average-space-allocation-pie-chart.png?auto=compress,format"
            alt=""
            class="float-left"
          />

          <p>
            Office design of the past was heavily weighted toward individual
            work, giving each employee his or her own spot to sit, focus, and
            work. This is a practical space allocation for offices of the past,
            where employees have clear 9-to-5 schedules and no option to work
            anywhere else. With the rise of the work from anywhere movement, our
            data tells us an over allocation of individual work space could lead
            to major underutilization.
          </p>
          <p>
            There has already been plenty of talk about the move to agile
            seating and space reservation strategies. The ideal is to create a
            system where the use of individual workspace can be variable or even
            completely unpredictable, and yet it is never wasted. Offices will
            likely double down on optimized-yet-safe reallocation of desk space
            in order to assign the remaining real estate to space types that
            might be more useful—or better, especially attractive for employees
            returning to the office.
          </p>
          <p class="strong">So what kinds of spaces might that be?</p>
        </div>
      </div>

      <div class="page-section bone-white-bg">
        <div class="inner">
          <h5 class="violet">Finding #3</h5>
          <h3>
            Pre-pandemic, collaborative spaces were 25% more utilized than
            spaces dedicated to individual work
          </h3>

          <img
            src="https://images.prismic.io/vergesense/a7bb9449-edea-4d8d-8433-e3a69c96fed3_average-individual-utilization.png?auto=compress,format"
            alt=""
            class="float-right"
          />

          <p>
            When looking at overall use, collaborative spaces saw 25% higher
            utilization than individual workspaces. This speaks to the rise of
            remote work and also the fact that while individual work can happen
            at home, in-person collaborative can’t.
          </p>
          <p>
            On the other hand, the pandemic forced us to learn that face-to-face
            isn’t required for collaboration. With that, it’s worth questioning
            whether this higher utilization of collaborative space is simply a
            function of how little of it there was.
          </p>
          <p class="strong">
            This leads us to the most interesting signal that could predict
            future office behavior.
          </p>
        </div>
      </div>

      <div class="page-section dark-blue-bg">
        <div class="inner">
          <h5 class="highlight-green">Finding #4</h5>
          <h3>
            Even in pandemic times, there’s been a 15% YoY increase in use of
            office space for collaboration
          </h3>

          <p>
            In other words, when people chose to come to the office in 2021,
            they more frequently chose it for collaboration than the year prior.
            This was the most impactful datapoint we found. Interest in using
            space for in-person collaboration has grown despite all the forces
            against: safety concerns, need for social distancing, and the growth
            of video conferencing as a commodity.
          </p>

          <img
            src="https://images.prismic.io/vergesense/6b118261-d673-46d8-aca6-7e4d82985d14_total-utilization.png?auto=compress,format"
            alt=""
            class="centered"
          />

          <p>
            This is our loudest signal predicting how employees will value the
            office moving forward—and it makes sense. In a world where work
            location is a choice, employees will return to the office to gain
            what they have been missing: time with each other.
          </p>
          <p>
            Workplace strategists have an opportunity to lean in and potentially
            create “collaboration hubs”, which are entire floors or buildings
            dedicated to collaboration. These spaces can be open to all lines of
            business and offer various seating arrangements, meeting rooms, and
            creative spaces.
          </p>
          <p class="strong">
            Perhaps, the return to work will birth a whole new set of
            collaboration room types.
          </p>
        </div>
      </div>

      <div class="page-section">
        <div class="inner">
          <h3>Looking forward</h3>
          <p>
            This data presents averages across our dataset, however it will be
            especially crucial for every business to understand its own space
            use patterns. Look at utilization of space types across your
            portfolio, and slice it across lines of business and campuses (i.e.
            San Francisco campus vs. Dublin).
          </p>
          <p>
            Consider running A/B tests to validate your decisions. You may learn
            that a particular space type is more effective in encouraging the
            collaboration employees want.
          </p>

          <div class="data-footer-callout">
            <div class="col dark-blue-bg">
              <h4>
                <span class="highlight-green">Download a PDF</span> of this
                report to share with your organization
              </h4>

              <CustomLink
                link={{
                  url:
                    "https://prismic-io.s3.amazonaws.com/vergesense/2011b4ae-f87f-472b-81a9-f9b729f4bd4a_VergeSense-Before-After-Covid-Office-Utilization-Data-Report-April2021.pdf",
                }}
                className="button violet on-dark"
              >
                Download Report
              </CustomLink>
            </div>
            <div class="col">
              <h4>Data Team</h4>
              <p>
                Our data collection and analysis was spearheaded by Workplace
                Innovation Group, an emerging think tank developed by
                VergeSense.
              </p>
              <p class="strong">Data Science and Analytics Experts:</p>
              <p>Mark Sinks, Faran Najam</p>
              <p class="strong">Workplace Strategists:</p>
              <p>Nellie Hayat, Shivani Chopra</p>
              <p class="strong">Author:</p>
              <p>Rebecca Corliss</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Data_BeforeAfterCovidOfficeStatistics
